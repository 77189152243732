import * as React from 'react'
import { MarketTradeTicker } from 'Components/MarketTradeTicker'
import { AdminSettings } from 'Components/AdminSettings'
import { TradeHistory } from 'Components/TradeHistory'
import { OrderBook } from 'Components/OrderBook'
import { UsersOrders } from 'Components/UsersOrders'
import { OrderComponent } from 'Components/OrderComponent'
import { Grid } from '@mui/material'
import { orderBookSubscribe, tradesSubscribe } from 'Helpers/Network'
import { readCookie } from 'Helpers/CookieHelper'

export default function AdminPanel ({ socket }) {
  const [orderBook, setOrderBook] = React.useState([])
  const [allTrades, setAllTrades] = React.useState([])
  const [myTrades, setMyTrades] = React.useState([])

  React.useEffect(() => {
    return orderBookSubscribe(socket, setOrderBook)
  }, [])

  React.useEffect(() => {
    return tradesSubscribe(socket, allTrades, setAllTrades, myTrades, setMyTrades)
  }, [])

  const isAdmin = readCookie('isAdmin')
  return (
    isAdmin === 'true'
      ? (
        <div className='full-height'>
          <Grid className='full-height' container spacing={2}>
            <Grid className='full-height' item xs={6}>
              <OrderBook orderBook={orderBook} />
              <MarketTradeTicker trades={allTrades} />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <OrderComponent />
                </Grid>
              </Grid>
              <AdminSettings socket={socket} />
              <UsersOrders admin socket={socket} />
              <TradeHistory trades={myTrades} />
            </Grid>
          </Grid>
        </div>
        )
      : (
        <div style={{ textAlign: 'center', paddingTop: '10vh' }}>Admin access only.</div>
        )
  )
}

import * as React from 'react'
import { Modal, Box, Typography } from '@mui/material'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

export const DisconnectedModal = ({ socket }) => {
  const [open, setOpen] = React.useState(false)
  React.useEffect(() => {
    const startDisconnectedPopupTimer = () => {
      setTimeout(() => {
        if (socket.disconnected) {
          setOpen(true)
        }
      }, 1000) // One second grace
    }
    startDisconnectedPopupTimer()
    socket.on('connect', () => {
      setOpen(false)
    })
    socket.on('disconnect', () => {
      startDisconnectedPopupTimer()
    })
  }, [])

  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          Connecting to server...
        </Typography>
        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          If the connection doesn't occur in 10 seconds, reload the page.
          If the connection doesn't occur in 30 seconds, contact the sysadmin.
        </Typography>
      </Box>
    </Modal>
  )
}

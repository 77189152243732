import * as React from 'react'
import 'Styles/Header.css'

import {
  NavLink
} from 'react-router-dom'
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  ThemeProvider,
  MenuItem,
  createTheme
} from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { authRedirect } from 'Helpers/Auth'

const getDesktopRoutes = (auth) => {
  return [
    {
      path: '/leaderboard',
      name: 'Leaderboard'
    },
    {
      path: '/projector',
      name: 'Observe'
    },
    {
      path: authRedirect('/compete'),
      name: 'Compete'
    }
  ]
}

const getMobileRoutes = (auth) => {
  return [
    {
      path: '/leaderboard',
      name: 'Leaderboard'
    },
    {
      path: '/projector',
      name: 'Observe'
    },
    {
      path: authRedirect('/compete'),
      name: 'Compete'
    },
    {
      // This login path needs to be different from the auth
      // redirect otherwise it crashes when we log in
      path: '/login#',
      name: 'Login'
    },
    {
      path: '/register',
      name: 'Register'
    }
  ]
}

const getLoginRoutes = (auth) => {
  const loggedOutPages = [
    {
      path: '/login',
      name: 'Login'
    },
    {
      path: '/register',
      name: 'Register'
    }
  ]
  const loggedInPages = [
    {
      path: '/logout',
      name: 'Log out'
    }
  ]
  return auth ? loggedInPages : loggedOutPages
}

const theme = createTheme({
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 48,
          minHeight: 48
        }
      }
    }
  }
})

export const Header = ({ auth }) => {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position='static' id='appbar-no-shadow'>
        <Container maxWidth={false}>
          <Toolbar disableGutters variant='dense' className='header-root'>
            <MobileDropDown auth={auth} />
            <NavLinkArray pages={getDesktopRoutes(auth)} />
            <LogoCentred variant='desktop' />
            <LogoCentred variant='mobile' />
            <NavLinkArray dropDown pages={getLoginRoutes(auth)} />
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  )
}

const NavLinkArray = ({ pages, dropDown }) => {
  let classes = 'navlink-array desktop-only'
  if (dropDown) {
    classes += ' setttings-dropdown'
  }
  return (
    <Box className={classes}>
      {pages.map((page) => (
        <NavLink
          className={({ isActive }) =>
            'navlink ' + (isActive ? 'navlink-active' : '')}
          key={page.path}
          to={page.path}
        >
          <div>
            {page.name}
          </div>
        </NavLink>
      ))}
    </Box>
  )
}

const LogoCentred = ({ variant }) => {
  return (
    <Box className={variant + '-only ' + variant + '-middle-title middle-title'}>
      <Box component='img' src='inv_logo_512.png' className='logo' />
      <Typography
        variant='h6'
        noWrap
        component='a'
        href='/'
        id='middle-title-text'
      >
        {variant === 'desktop' ? 'SQT Mock Trading Engine' : 'SQT Mock'}
      </Typography>
    </Box>
  )
}

const MobileDropDown = (auth) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const handleOpenNavMenu = (event) => { setAnchorElNav(event.currentTarget) }
  const handleCloseNavMenu = () => { setAnchorElNav(null) }

  return (
    <Box className='header-side-elements mobile-only'>
      <IconButton
        size='large'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleOpenNavMenu}
        color='inherit'
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        className='mobile-only-block'
      >
        {getMobileRoutes(auth).map((page) => (
          <MenuItem key={page.path} onClick={handleCloseNavMenu}>
            <NavLink
              className='navlink-drop'
              to={page.path}
            >
              {page.name}
            </NavLink>
          </MenuItem>
        ))}
      </Menu>

    </Box>
  )
}

import 'Styles/App.css'
import 'Styles/LandingPage.css'
import * as React from 'react'
import { Button, Grid, Box } from '@mui/material'
import { MarketTradeTicker } from 'Components/MarketTradeTicker'
import { OrderBook } from 'Components/OrderBook'
import { Leaderboard } from 'Components/Leaderboard'
import { LandingPageData } from 'Helpers/LandingPageData'
import { authRedirect, doLogout } from 'Helpers/Auth'

export const LandingPage = ({ logout, setAuth }) => {
  React.useEffect(() => {
    if (logout) {
      doLogout()
      setAuth(false)
    }
  }, [])

  return (
    <Grid container id='landing-container'>
      <Grid item xs={12} lg={4}>
        <div id='info-box'>
          <img src='logo_nobg.png' id='landing-img' alt='sqt_logo' />
          <h1 id='catch-phrase'>No more dry-erase stains.</h1>
          <h2>Mock trade with SQT's mock trading engine for the <i>cleanest</i> experience your fingers can get.</h2>
          <Button href={authRedirect('/compete')} variant='contained' id='get-started-btn'>
            Get started
          </Button>
        </div>
      </Grid>
      <Grid item xs={8} id='component-floaters'>
        <Box id='floating-ticker' className='desktop-only landing-float'>
          <MarketTradeTicker trades={LandingPageData.trades} noFlash />
        </Box>
        <Box id='floating-orderbook' className='desktop-only landing-float'>
          <OrderBook orderBook={LandingPageData.orderBook} bestAsk={76} bestBid={75} />
        </Box>
        <Box id='floating-leaderboard' className='desktopOnly landing-float'>
          <Leaderboard leaderboard={LandingPageData.leaderboard} noSwitch />
        </Box>
      </Grid>
    </Grid>
  )
}

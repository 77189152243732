import * as React from 'react'
import 'Styles/UsersOrders.css'
import { apiURL } from 'Helpers/UrlHelper'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography
} from '@mui/material'
import { readCookie } from 'Helpers/CookieHelper'

const columns = [
  { id: 'side', label: 'Side', minWidth: 70 },
  { id: 'price', label: 'Price', minWidth: 70 },
  { id: 'volume', label: 'Volume', minWidth: 70 }
]

const cancelAllOrders = (orders) => {
  for (let i = 0; i < orders.length; i++) {
    cancelOrder(orders[i].userName, orders[i].id)
  }
}

const cancelOrder = (userName, id) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + readCookie('accessToken')
    },
    // body NEEDS to be an array so that we can mass cancel
    // orders later down the line. Currently just an array of one item.
    body: JSON.stringify([{ userName, id }])
  }

  fetch(apiURL() + '/cancelOrder', options)
}

export const UsersOrders = ({ admin, socket }) => {
  // UserOrders manages its own state, because in order for the component to be
  // more reusable, we decouple the notion of orders from whatever imports
  // this component.
  const [orders, setOrders] = React.useState([])

  const setO = (msg) => {
    if (!admin) {
      setOrders(msg.filter((order) => order.userName === readCookie('username')))
    } else {
      setOrders(msg)
    }
  }

  React.useEffect(() => {
    socket.emit('fetchUserOrders', undefined, setO)
    socket.on('updateUserOrders', setO)
    return () => socket.off('updateUserOrders', setO)
  }, [])

  const amendedColumns = [...columns]
  if (admin) {
    amendedColumns.push(
      { id: 'userName', label: 'Name', minWidth: 70 }
    )
  }
  // buffer for cancel orders column just whitespace
  amendedColumns.push(
    { minWidth: 70 }
  )

  return (
    <Paper className='order-list'>
      <Typography variant='h4' component='h2' className='table-title'>
        {admin ? 'All orders' : 'Resting orders'}
      </Typography>
      <TableContainer className='uo-table'>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {amendedColumns.map((column) => (
                <OrderTableRow column={column} key={column.id} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row) => (
              <UserOrder key={row.id} row={row} admin={admin} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className='uo-filler' />
      <Button color='error' variant='outlined' className='cancel-all-btn' onClick={() => { cancelAllOrders(orders) }}>Cancel All</Button>
    </Paper>
  )
}

const OrderTableRow = ({ column }) => {
  return (
    <TableCell
      key={column.id}
      align='center'
      style={{ minWidth: column.minWidth }}
    >
      {column.label}
    </TableCell>
  )
}

const UserOrder = ({ row, admin }) => {
  return (
    <TableRow
      key={row.nonce}
    >
      <TableCell align='center'>{row.side}</TableCell>
      <TableCell align='center'>{row.price}</TableCell>
      <TableCell align='center'>{row.volume}</TableCell>
      {admin ? <TableCell align='center'>{row.userName}</TableCell> : undefined}
      <TableCell align='center'>
        <Button color='error' onClick={() => { cancelOrder(row.userName, row.id) }}>Cancel</Button>
      </TableCell>
    </TableRow>
  )
}

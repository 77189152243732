import * as React from 'react'
import 'Styles/MarketTradeTicker.css'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

const columns = [
  { id: 'time', label: 'Time', minWidth: 70 },
  { id: 'price', label: 'Price', minWidth: 70 },
  { id: 'quantity', label: 'Quantity', minWidth: 70 },
  { id: 'buyerId', label: 'Buyer Id', minWidth: 70 },
  { id: 'sellerId', label: 'Seller Id', minWidth: 70 }
]

export const MarketTradeTicker = ({ trades, noFlash }) => {
  // We can't reverse in-place because javascript, so make a copy
  const tradesCopy = trades.map(i => i)
  tradesCopy.reverse()

  return (
    <Paper sx={{ width: '100%' }} className='paper-col-flex full-height'>
      <Typography variant='h4' component='h2' className='table-title'>
        Market Trade Ticker
      </Typography>
      <TableContainer>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow />
            <TableRow>
              {columns.map((column, key) => (
                <MarketTradeTickerColumn column={column} noFlash={noFlash} key={key} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tradesCopy.map((row) => (
              // Use the row's nonce as the key so that the flashing works
              <MarketTradeTickerRow row={row} key={row.nonce} noFlash={noFlash} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

const MarketTradeTickerColumn = ({ column }) => {
  return (
    <TableCell
      key={column.id}
      align='center'
      style={{ minWidth: column.minWidth }}
    >
      {column.label}
    </TableCell>
  )
}

const MarketTradeTickerRow = ({ row, noFlash }) => {
  return (
    <TableRow
      className={noFlash ? '' : 'market-ticker-row'}
    >
      <TableCell align='center'>{new Date(row.time * 1000).toLocaleTimeString('en-US')}</TableCell>
      <TableCell align='center'>{row.price}</TableCell>
      <TableCell align='center'>{row.quantity}</TableCell>
      <TableCell align='center'>{row.buyerId}</TableCell>
      <TableCell align='center'>{row.sellerId}</TableCell>
    </TableRow>
  )
}

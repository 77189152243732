import * as React from 'react'
import 'Styles/OrderComponent.css'
import {
  Box,
  Button,
  Typography,
  Modal,
  InputLabel,
  FormControl,
  OutlinedInput,
  TextField,
  IconButton,
  Grid,
  InputAdornment
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { IOCButtons } from './IOCButtons'
import { getUsername } from 'Helpers/Auth'
import { submitBuySellOrder } from 'Helpers/Network'

const nonNegative = (number) => { return number >= 0 ? number : 0 }

export const OrderComponent = ({ bestBid, bestAsk, isCompetitor }) => {
  const [tradeModalVisible, setTradeModalVisible] = React.useState(false)
  const tradeClose = () => setTradeModalVisible(false)

  const setNumeric = () => {
    const o = order
    o.price = Number(order.price)
    o.volume = Number(order.volume)
    return o
  }

  const defaultNotificationMessage = 'Submit an order'
  const [notificationMessage, setNotificationMessage] = React.useState(defaultNotificationMessage)
  const [timeout, setClearTimeout] = React.useState(-1)
  const submitOrder = () => {
    const currOrder = setNumeric()
    // Set the message, and then quickly reset it
    submitBuySellOrder(currOrder, (message) => {
      setNotificationMessage(message)
      window.clearTimeout(timeout)
      setClearTimeout(window.setTimeout(() => { setNotificationMessage(defaultNotificationMessage) }, 3000))
    })
  }

  const [order, setOrderValues] = React.useState({
    side: 'Buy', // Setting some defaults to make it easier to test
    price: 0,
    volume: 0,
    userName: getUsername()
  })

  const handleOrderChange = (prop) => (event) => {
    if (prop === 'volume') {
      setOrderValues({ ...order, [prop]: nonNegative(event.target.value) })
    } else {
      setOrderValues({ ...order, [prop]: event.target.value })
    }
  }

  const setOrder = (prop, value) => {
    if (prop === 'volume') {
      setOrderValues({ ...order, [prop]: nonNegative(value) })
    } else {
      setOrderValues({ ...order, [prop]: value })
    }
  }

  const IOCButtonsWithGrid = (
    <Grid class='' item xs={12}>
      <IOCButtons bestBid={bestBid} bestAsk={bestAsk} />
    </Grid>
  )

  return (
    <div>
      <Box textAlign='center' className='order-component'>
        <Typography id='order-modal' variant='h6' component='h2' sx={{ color: notificationMessage.startsWith('ERROR') ? 'red' : 'black' }}>
          {notificationMessage}
        </Typography>
        <Grid container spacing={0.5}>
          {
            isCompetitor
              ? IOCButtonsWithGrid
              : null
          }
          <Grid item xs={12}>
            <PriceInput price={order.price} handleOrderChange={handleOrderChange} setOrder={setOrder} />
          </Grid>
          <Grid item xs={12}>
            <QuantityInput volume={order.volume} handleOrderChange={handleOrderChange} setOrder={setOrder} />
          </Grid>
          <Grid item xs={12}>
            <SideInput order={order} handleOrderChange={setOrderValues} />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={submitOrder} variant='outlined' fullWidth>Submit</Button>
          </Grid>
        </Grid>
      </Box>
      <OrderConfirmationModal open={tradeModalVisible} onClose={tradeClose} />
    </div>
  )
}

export const PriceInput = ({ price, handleOrderChange, setOrder }) => {
  return (
    <FormControl className='order-input' fullWidth>
      <Grid container fullWidth>
        <Grid item xs={2} sm={0} className='t-iface-mobile'>
          <IconButton data-testid='pricePlusBtn' size='large' onClick={() => setOrder('price', (Number(price) + 1))}>
            <AddIcon fontSize='inherit' />
          </IconButton>
        </Grid>
        <Grid item xs={8} sm={12}>
          <OutlinedInput
            className='order-input'
            label='Price'
            value={price}
            fullWidth
            onChange={handleOrderChange('price')}
            startAdornment={
              <>
                {/* This inputLabel needs to be nested otherwise it juts out awkwardly */}
                <InputLabel>Price</InputLabel>
                <InputAdornment position='start'>$</InputAdornment>
              </>
}
          />
        </Grid>
        <Grid item xs={2} sm={0} className='t-iface-mobile'>
          <IconButton data-testid='priceMinusBtn' size='large' onClick={() => setOrder('price', nonNegative(Number(price) - 1))}>
            <RemoveIcon fontSize='inherit' />
          </IconButton>
        </Grid>
      </Grid>
    </FormControl>
  )
}

const QuantityInput = ({ volume, handleOrderChange, setOrder }) => {
  return (
    <FormControl className='order-input' fullWidth>
      <Grid container fullWidth>
        <Grid item xs={2} sm={0} className='t-iface-mobile'>
          <IconButton data-testid='quantityPlusBtn' size='large' onClick={() => setOrder('volume', (Number(volume) + 1))}>
            <AddIcon fontSize='inherit' />
          </IconButton>
        </Grid>
        <Grid item xs={8} lg={12}>
          <TextField
            className='order-input'
            id='outlined-number'
            label='Volume'
            value={volume}
            fullWidth
            onChange={handleOrderChange('volume')}
          />
        </Grid>
        <Grid item xs={2} sm={0} className='t-iface-mobile'>
          <IconButton data-testid='quantityMinusBtn' size='large' onClick={() => setOrder('volume', nonNegative(Number(volume) - 1))}>
            <RemoveIcon fontSize='inherit' />
          </IconButton>
        </Grid>
      </Grid>
    </FormControl>
  )
}

const SideInput = ({ order, handleOrderChange }) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <Button
          color='error' variant={order.side === 'Sell' ? 'contained' : 'outlined'} id='sellBtn'
          onClick={() => handleOrderChange({ ...order, side: 'Sell' })}
          fullWidth
        >
          SELL
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          color='success' variant={order.side === 'Buy' ? 'contained' : 'outlined'} id='orderBtn'
          onClick={() => handleOrderChange({ ...order, side: 'Buy' })}
          fullWidth
        >
          BUY
        </Button>
      </Grid>
    </Grid>
  )
}

const OrderConfirmationModal = ({ open, tradeClose }) => {
  return (
    <Modal
      open={open}
      onClose={tradeClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box textAlign='center'>
        <Typography>
          Order Placed!
        </Typography>
      </Box>
    </Modal>
  )
}

export const LandingPageData = {
  trades: [
    { time: 1659066492.3696787, price: 29, quantity: 15, buyerId: 'Kevin', sellerId: 'Shirley', nonce: 13 },
    { time: 1659066492.3706207, price: 36, quantity: 13, buyerId: 'Kevin', sellerId: 'Sean', nonce: 14 },
    { time: 1659066492.3706791, price: 52, quantity: 30, buyerId: 'Kevin', sellerId: 'Alex', nonce: 15 },
    { time: 1659066495.0106063, price: 57, quantity: 39, buyerId: 'Alex', sellerId: 'Sean', nonce: 16 },
    { time: 1659066496.0189059, price: 54, quantity: 26, buyerId: 'Kevin', sellerId: 'Sean', nonce: 17 },
    { time: 1659066497.016347, price: 14, quantity: 33, buyerId: 'Shirley', sellerId: 'Sean', nonce: 18 },
    { time: 1659066498.0250056, price: 10, quantity: 16, buyerId: 'Sean', sellerId: 'Sean', nonce: 19 }
  ],
  orderBook: [
    { bid: '', price: 94, ask: 94 },
    { bid: '', price: 90, ask: 89 },
    { bid: '', price: 89, ask: 12 },
    { bid: '', price: 86, ask: 64 },
    { bid: '', price: 83, ask: 37 },
    { bid: '', price: 77, ask: 11 },
    { bid: '', price: 76, ask: 4 },
    { bid: 10, price: 75, ask: '' },
    { bid: 10, price: 74, ask: '' },
    { bid: 10, price: 73, ask: '' },
    { bid: 10, price: 72, ask: '' },
    { bid: 10, price: 71, ask: '' },
    { bid: 10, price: 70, ask: '' },
    { bid: 10, price: 69, ask: '' },
    { bid: 10, price: 68, ask: '' }
  ],
  leaderboard: {
    numRounds: 3,
    playerScores: [
      {
        userName: 'Sean',
        profit: 7757,
        roundProfits: { 0: 1241, 1: 3234, 2: 3282 },
        roundPositions: { 0: 10, 1: -5, 2: 0 }
      }, {
        userName: 'Shirley',
        profit: 6362,
        roundProfits: { 0: 2362, 1: -1293, 2: 5293 },
        roundPositions: { 0: 5, 1: 7, 2: 11 }
      }, {
        userName: 'Alex',
        profit: -2581,
        roundProfits: { 0: -600, 1: 94, 2: -2075 },
        roundPositions: { 0: -10, 1: 2, 2: -3 }
      }, {
        userName: 'Kevin',
        profit: -10738,
        roundProfits: { 0: -3003, 1: -1235, 2: -6500 },
        roundPositions: { 0: -5, 1: -4, 2: -8 }
      }
    ]
  }
}

import * as React from 'react'
import {
  Paper,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import 'Styles/Leaderboard.css'

const FormattedValueCell = ({ amt, key }) => {
  if (amt === '-') {
    return (<TableCell key={key} align='center'>-</TableCell>)
  } else {
    const redStyle = { color: amt >= 0 ? 'black' : 'red' }

    // Corrects "$-100" to "-$100" when displaying on leaderboard
    const signCorrectedText = amt >= 0 ? `$${amt.toFixed(2)}` : `-$${(-amt).toFixed(2)}`

    return (
      <TableCell key={key} align='center' style={redStyle}>
        {signCorrectedText}
      </TableCell>
    )
  }
}

const makeLeaderBoardRows = (leaderboard, sortByMM) => {
  let playerRows = []
  if (leaderboard.playerScores !== undefined) {
    playerRows = leaderboard.playerScores.map(player => {
      const row = {
        userName: player.userName,
        profit: player.profit,
        totalPosition: Object.values(player.roundPositions).reduce((total, position) => total + position, 0),
        rounds: Array(leaderboard.numRounds).fill('-')
      }
      row.profitPerRisk = row.profit / (Math.abs(row.totalPosition) + 1)
      // Fill in rounds using roundProfits sparse dictionary
      for (const i in player.roundProfits) {
        row.rounds[i] = player.roundProfits[i]
      }
      return row
    })

    // Sort rows in descending order
    if (sortByMM) {
      playerRows = playerRows.sort((a, b) => b.profitPerRisk - a.profitPerRisk)
    } else {
      playerRows = playerRows.sort((a, b) => b.profit - a.profit)
    }
  }

  // Generate rows for the table
  const playerTableRows = playerRows.map((row) => (
    <TableRow key={row.userName}>
      <TableCell align='center'>{row.userName}</TableCell>
      <TableCell align='center'>{row.totalPosition}</TableCell>
      <FormattedValueCell amt={row.profit} />
      <FormattedValueCell amt={row.profitPerRisk} />
    </TableRow>
  ))

  return playerTableRows
}

const makeTableHeader = (leaderboard) => {
  const tableHeaderColumns = [<TableCell align='center' key='userName'>Player</TableCell>]
  tableHeaderColumns.push(<TableCell align='center' key='position'>Pre-Settle Position</TableCell>)
  tableHeaderColumns.push(<TableCell align='center' key='profit'>Final Profit</TableCell>)
  tableHeaderColumns.push(<TableCell align='center' key='ppr'>Profit-Per-Risk</TableCell>)

  // Add columns for rounds: TODO when we restore rounds functionality

  return tableHeaderColumns
}

export const Leaderboard = ({ leaderboard, noSwitch }) => {
  const [sortByMM, setsortByMM] = React.useState(false)
  return (
    <Paper sx={{ width: '100%' }} className='full-height paper-col-flex'>
      <Typography variant='h4' component='h2' className='table-title'>
        Leaderboard
      </Typography>
      {!noSwitch
        ? <FormControlLabel
            control={
              <Switch
                checked={sortByMM}
                onChange={(e) => setsortByMM(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
          }
            label='Sort by Profit-Per-Risk'
          />
        : null}
      <TableContainer>
        <Table stickyHeader aria-label='sticky table' className='leaderboard-table'>
          <TableHead>
            <TableRow>
              {makeTableHeader(leaderboard)}
            </TableRow>
          </TableHead>
          <TableBody>
            {makeLeaderBoardRows(leaderboard, sortByMM)}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

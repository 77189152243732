import * as React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import 'Styles/OrderBook.css'

// Export this const for testing
// Take a large number of order book display rows,
// and then let the UI truncate them automatically
// using some CSS
export const orderbookDisplayRows = 30

const columns = [
  { id: 'bid', label: 'Bid', minWidth: 70 },
  { id: 'price', label: 'Price', minWidth: 70 },
  { id: 'ask', label: 'Ask', minWidth: 70 }
]

const OrderTableRow = ({ row }) => (
  <TableRow>
    <TableCell align='center'>{row.bid}</TableCell>
    <TableCell align='center'>{row.price}</TableCell>
    <TableCell align='center'>{row.ask}</TableCell>
  </TableRow>
)

const OrderBookRows = ({ orderBook }) => {
  // Get best ask price
  let bestAskPrice
  const askOrders = orderBook
    .filter(i => i.ask !== '')
    .sort((a, b) => a.price - b.price)
  if (askOrders.length > 0) {
    bestAskPrice = askOrders[0].price
  }

  // Get best bid price
  let bestBidPrice
  const bidOrders = orderBook
    .filter(i => i.bid !== '')
    .sort((a, b) => b.price - a.price)
  if (bidOrders.length > 0) {
    bestBidPrice = bidOrders[0].price
  }

  // Create an empty order book
  const firstAskIndex = Math.floor(orderbookDisplayRows / 2) - 1
  const firstBidIndex = Math.floor(orderbookDisplayRows / 2)
  const centeredOrderbook = Array(orderbookDisplayRows).fill()
    .map((_, i) => (
      <TableRow key={i}>
        <TableCell align='center' colSpan={3}>
          <br />
        </TableCell>
      </TableRow>
    ))

  // Fill in asks
  if (bestAskPrice) {
    for (let i = firstAskIndex; i >= 0; i--) {
      if (firstAskIndex - i < askOrders.length) {
        centeredOrderbook[i] = (
          <OrderTableRow key={i} row={askOrders[firstAskIndex - i]} />
        )
      }
    }
  } else {
    centeredOrderbook[firstAskIndex] = (
      <TableRow key={firstAskIndex}>
        <TableCell align='center' colSpan={3}>
          --- No ask orders ---
        </TableCell>
      </TableRow>
    )
  }

  // Fill in bids
  if (bestBidPrice) {
    for (let i = 0; i < orderbookDisplayRows - firstBidIndex; i++) {
      if (i < bidOrders.length) {
        centeredOrderbook[firstBidIndex + i] = <OrderTableRow key={firstBidIndex + i} row={bidOrders[i]} />
      }
    }
  } else {
    centeredOrderbook[firstBidIndex] = (
      <TableRow key={firstBidIndex}>
        <TableCell align='center' colSpan={3}>
          --- No bid orders ---
        </TableCell>
      </TableRow>
    )
  }
  return centeredOrderbook
}

const OrderBookColumn = ({ column, key }) => {
  return (
    <TableCell
      key={key}
      align={column.align}
      style={{ minWidth: column.minWidth }}
    >
      {column.label}
    </TableCell>
  )
}

export const OrderBook = ({ orderBook }) => {
  return (
    <Paper sx={{ width: '100%' }} className='full-height paper-col-flex'>
      <Typography variant='h4' component='h2' className='table-title'>
        Order Book
      </Typography>
      <TableContainer>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column, key) => (
                <OrderBookColumn column={column} key={key} />
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <div className='orderbook-center-quotes-outer'>
        <div className='orderbook-center-quotes'>
          <TableContainer>
            <Table stickyHeader aria-label='sticky table'>
              <TableBody data-testid='orderBookTable'>
                <OrderBookRows orderBook={orderBook} />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

    </Paper>
  )
}

import * as React from 'react'
import 'Styles/App.css'
import {
  Box,
  Typography,
  TextField
} from '@mui/material'
import { processCommand } from 'Helpers/QuickOrderCommandProcessors'
import { getUsername } from 'Helpers/Auth'

export const QuickOrderComponent = ({ isAdmin }) => {
  const [cmd, setCmd] = React.useState('')

  let defaultNotificationMessage = 'Syntax: "[b/s] [qty] [price]"'
  if (isAdmin) {
    defaultNotificationMessage = 'Syntax: "[username] [b/s/c/c!] [qty] [price]"'
  }
  const [notificationMessage, setNotificationMessage] = React.useState(defaultNotificationMessage)
  const [timeout, setClearTimeout] = React.useState(-1)

  const setTempNotificationMessage = (message) => {
    setNotificationMessage(message)
    window.clearTimeout(timeout)
    setClearTimeout(window.setTimeout(() => { setNotificationMessage(defaultNotificationMessage) }, 3000))
  }

  const checkAndSubmit = (event) => {
    if (event.key === 'Enter') {
      try {
        if (isAdmin) {
          processCommand(cmd, setTempNotificationMessage)
        } else {
          processCommand(cmd, setTempNotificationMessage, getUsername())
        }
        event.target.value = ''
      } catch (error) {
        setTempNotificationMessage(error.message)
      }
    }
  }

  return (
    <div>
      <Box textAlign='center' className='order-component'>
        <Typography variant='h6' component='h2'>
          Cascade rapid entry (Buy/Sell only)
        </Typography>
        <TextField
          sx={{ width: '100%' }}
          command={cmd}
          onChange={(event) => setCmd(event.target.value)}
          onKeyPress={checkAndSubmit}
        />
        <Typography variant='p' component='p' sx={{ color: notificationMessage.startsWith('ERROR') ? 'red' : 'black' }}>
          {notificationMessage}
        </Typography>
      </Box>
    </div>
  )
}

import * as React from 'react'
import 'Styles/App.css'
import {
  FormControlLabel,
  Switch
} from '@mui/material'

import { apiURL } from 'Helpers/UrlHelper'

export function RandomOrderGenerator () {
  const [generatorRunning, setGeneratorRunning] = React.useState(false)

  const submitOrder = (order) => {
    const options = {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Bearer '
      },
      body: JSON.stringify(order)
    }

    fetch(apiURL() + '/placeOrder', options)
  }

  React.useEffect(() => {
    const makeRandomOrders = setInterval(() => {
      // Use functional setGeneratorRunning to get the current value
      setGeneratorRunning((generatorRunning) => {
        if (generatorRunning) {
          const order = {
            side: Math.random() > 0.5 ? 'Buy' : 'Sell',
            price: Math.floor(Math.random() * 100),
            volume: Math.floor(Math.random() * 100),
            id: 0,
            userName: userNames[Math.floor(Math.random() * userNames.length)]
          }
          submitOrder(order)
        }
        return generatorRunning
      })
    }, 1000)
    return () => clearInterval(makeRandomOrders)
  }, [])
  const toggleOnState = (ev) => {
    setGeneratorRunning(ev.target.checked)
  }
  return (
    <FormControlLabel control={<Switch checked={generatorRunning} onChange={toggleOnState} />} label='Generate Random Orders' />
  )
}

// Bank of 100 usernames so that we can check leaderboard scrolling
const userNames = [
  'Karl',
  'Steven',
  'Sean',
  'Dimi',
  'Louis',
  'Megan',
  'Noah',
  'Judith',
  'Walter',
  'Evelyn',
  'Ethan',
  'Joan',
  'Kyle',
  'Christina',
  'Peter',
  'Lauren',
  'Zachary',
  'Kelly',
  'Douglas',
  'Victoria',
  'Henry',
  'Virginia',
  'Nathan',
  'Joyce',
  'Adam',
  'Olivia',
  'Jose',
  'Julie',
  'Aaron',
  'Ruth',
  'Tyler',
  'Diane',
  'Jerry',
  'Heather',
  'Dennis',
  'Maria',
  'Jack',
  'Catherine',
  'Raymond',
  'Janet',
  'Patrick',
  'Carolyn',
  'Frank',
  'Rachel',
  'Alexander',
  'Debra',
  'Gregory',
  'Christine',
  'Samuel',
  'Michelle',
  'Andrew',
  'Donna',
  'Paul',
  'Emily',
  'Steven',
  'Kimberly',
  'Donald',
  'Ashley',
  'Mark',
  'Sandra',
  'Anthony',
  'Margaret',
  'Matthew',
  'Betty',
  'Daniel',
  'Nancy',
  'Christopher',
  'Lisa',
  'Charles',
  'Karen',
  'Thomas',
  'Sarah',
  'Joseph',
  'Jessica',
  'Richard',
  'Susan',
  'William',
  'Barbara',
  'David',
  'Elizabeth',
  'Michael',
  'Linda',
  'John',
  'Jennifer',
  'Robert',
  'Patricia',
  'James',
  'Mary'
]

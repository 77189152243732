import * as React from 'react'
import {
  Button,
  FormGroup,
  Grid,
  Typography,
  InputLabel,
  OutlinedInput,
  InputAdornment
} from '@mui/material'
import { RandomOrderGenerator } from './RandomOrderGenerator'
import { RoundManagement } from './RoundManagement'
import { cancelAllOrders, submitCompetitionState, httpRequest } from 'Helpers/Network'

export const AdminSettings = ({ socket }) => {
  const [resolvePrice, setresolvePrice] = React.useState(50)
  const [compStatus, setCompStatus] = React.useState('')
  const banUserButtonRef = React.useRef(null)

  React.useEffect(() => {
    // Fixes incorrect state after page refresh
    socket.emit('getCompetitionState', undefined, (recv) => {
      setCompStatus(recv.state)
    })

    // subscribe to future updates
    socket.on('competitionStateUpdated', setCompStatus)
    return () => socket.off('competitionStateUpdated', setCompStatus)
  }, [])

  const handleBanUser = () => {
    const username = banUserButtonRef.current.value
    if (username === '') {
      window.alert('Please enter a username!')
    } else {
      httpRequest(
        {
          username
        },
        '/remove',
        'DELETE',
        true,
        (res) => {
          if (res.msg.startsWith('Unable')) {
            window.alert(`Failed to ban user ${username}.`)
          } else {
            window.alert(`Successfully banned user ${username}!`)
          }
        }
      )
    }
  }

  const setCompetitionState = (desiredState) => {
    if (isNaN(Number(resolvePrice))) {
      window.alert('Resolve price is not a valid number!')
    } else {
      const setCompStateArgs = {
        desiredState,
        resolvePrice: Number(resolvePrice)
      }
      if (desiredState === 'PAUSE') {
        setCompStateArgs.desiredState = 'HALTED'
        setCompStateArgs.resolvePrice = null
      }
      submitCompetitionState(setCompStateArgs)
    }
  }

  const toggleCompetitionState = () => {
    if (compStatus === 'RUNNING') setCompetitionState('PAUSE')
    else setCompetitionState('RUNNING')
  }

  const confirmBeforeResolve = () => {
    if (
      window.confirm(
        'Reset entire competition? (This clears cleared trades for this' +
          `round as well. To just clear all orders, use the "Clear all orders" button.) 
      THIS IS IRREVERSIBLE!`
      )
    ) {
      setCompetitionState('RESET')
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormGroup>
          <Typography variant='h4' component='h2'>
            Competition controls
          </Typography>

          {/* <FormControlLabel control={<Switch defaultChecked />} label="Allow IOC orders" />
        <FormControlLabel control={<Switch />} label="Allow xyz" /> */}
          <Typography variant='h6' component='h2'>
            Current competition status:{' '}
            <span
              style={{ color: compStatus === 'RUNNING' ? 'green' : 'orange' }}
            >
              {compStatus}
            </span>
          </Typography>
          <FormGroup row>
            <Button
              variant='outlined'
              sx={{ flex: '1 1 auto' }}
              onClick={toggleCompetitionState}
            >
              {compStatus === 'RUNNING' ? 'Pause' : 'Start'} Competition
            </Button>
            <Button
              variant='outlined'
              sx={{ flex: '1 1 auto' }}
              onClick={confirmBeforeResolve}
            >
              Reset Competition
            </Button>
          </FormGroup>
          <RandomOrderGenerator />

          {/* input box and button to ban user based on username */}
          <InputLabel htmlFor='outlined-adornment-resolution'>
            Ban User
          </InputLabel>
          <OutlinedInput
            id='outlined-adornment-resolution'
            label='Ban User'
            startAdornment={
              <InputAdornment position='start'>Username</InputAdornment>
            }
            inputRef={banUserButtonRef}
          />
          <Button
            variant='outlined'
            sx={{ flex: '1 1 auto' }}
            onClick={handleBanUser}
          >
            Ban
          </Button>

          <InputLabel htmlFor='outlined-adornment-resolution'>
            Resolution True Value
          </InputLabel>
          <OutlinedInput
            id='outlined-adornment-resolution'
            // textAlign={"center"}
            value={resolvePrice}
            type='number'
            onChange={(evt) => {
              setresolvePrice(evt.target.value)
            }}
            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
            label='Resolution True Value'
          />
          <FormGroup row>
            <Button variant='outlined' sx={{ flex: '1 1 auto' }} onClick={() => setCompetitionState('HALTED')}>Resolve Competition</Button>
            <Button variant='outlined' sx={{ flex: '1 1 auto' }} onClick={() => cancelAllOrders()}>Clear All Orders</Button>
          </FormGroup>
          <RoundManagement socket={socket} />
        </FormGroup>
      </Grid>
    </Grid>
  )
}

import * as React from 'react'
import 'Styles/TradingInterface.css'
import { MarketTradeTicker } from 'Components/MarketTradeTicker'
import { TradeHistory } from 'Components/TradeHistory'
import { OrderBook } from 'Components/OrderBook'
import { UsersOrders } from 'Components/UsersOrders'
import { OrderComponent } from 'Components/OrderComponent'
import { QuickOrderComponent } from 'Components/QuickOrderComponent'
import { Grid } from '@mui/material'
import { PositionMonitor } from 'Components/PositionMonitor'
import { orderBookSubscribe, tradesSubscribe } from 'Helpers/Network'
import { useNavigate } from 'react-router-dom'

import { isLoggedIn } from 'Helpers/Auth'

export default function TradingInterface ({ socket }) {
  const [orderBook, setOrderBook] = React.useState([])
  const [bestQuotes, setBestQuotes] = React.useState({
    bid: 0,
    ask: 0
  })
  const [allTrades, setAllTrades] = React.useState([])
  const [myTrades, setMyTrades] = React.useState([])
  const navigate = useNavigate()
  React.useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/login')
    }
  }, [])

  React.useEffect(() => {
    return orderBookSubscribe(socket, setOrderBook, setBestQuotes)
  }, [])

  React.useEffect(() => {
    return tradesSubscribe(socket, allTrades, setAllTrades, myTrades, setMyTrades)
  }, [])

  return (
    <Grid className='full-height' container spacing={2}>
      <Grid item xs={12} sm={6} className='t-iface-desktop full-height'>
        <div className='t-iface-lpanel'>
          <div className='t-iface-orderbook'>
            <OrderBook orderBook={orderBook} />
          </div>
          <div className='t-iface-market-ticker'>
            <MarketTradeTicker trades={allTrades} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} className='t-iface-rpanel full-height'>
        <Grid container className='t-iface-rupperbox'>
          <Grid item xs={12} sm={6}>
            <OrderComponent bestBid={bestQuotes.bid} bestAsk={bestQuotes.ask} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PositionMonitor myTrades={myTrades} />
          </Grid>
          <Grid item xs={0} sm={12} className='t-iface-desktop'>
            <QuickOrderComponent />
          </Grid>
        </Grid>
        <div className='t-iface-rlowerbox'>
          <UsersOrders admin={false} socket={socket} />
          <div className='t-iface-desktop t-iface-trade-history-ctr'>
            <TradeHistory trades={myTrades} />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

import React from 'react'
import {
  TextField,
  Grid,
  Button
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import ResponseModal from 'Components/ResponseModal'
import { doAuth } from 'Helpers/Network'
import { updateCookie } from 'Helpers/CookieHelper'

export const AuthScreen = ({ setAuth, register = false }) => {
  const [name, setName] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')

  const navigate = useNavigate()

  const doLogin = () => {
    updateCookie('isAdmin', false)
    if (register) {
      doAuth('/register', username, password, name).then(() => {
        navigate('/login')
      }).catch(async (msg) => {
        setError((await msg.json()).msg)
      })
    } else {
      doAuth('/login', username, password, name).then(() => {
        navigate('/compete')
        setAuth(true)
      }).catch(async (msg) => {
        setError((await msg.json()).msg)
      })
    }
  }

  return (
    <>
      <form onSubmit={doLogin}>
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          {register
            ? <TextField
                variant='standard'
                id='name-input'
                label='Name'
                margin='normal'
                name='Name'
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            : null}
          <TextField
            variant='standard'
            id='name-input'
            label='Username'
            margin='normal'
            name='username'
            required
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <TextField
            variant='standard'
            id='password-input'
            type='password'
            label='Password'
            margin='normal'
            name='password'
            required
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <Button id='submitLogin' variant='outlined' onClick={() => doLogin()}>{register ? 'Register' : 'Login'}</Button>
        </Grid>
      </form>
      {error !== ''
        ? (
          <ResponseModal setError={setError} msg={error} visible />
          )
        : (<></>)}
    </>
  )
}

import React from 'react'

import {
  Typography,
  Box,
  Button,
  Modal
} from '@mui/material/'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

export const ResponseModal = ({ setError, msg, visible }) => {
  const [open, setOpen] = React.useState(visible)

  const close = () => {
    setError('')
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onClose={() => { close(false) }}
      aria-open={open}
      id='responseModal'
    >
      <Box sx={style} textAlign='center'>
        <Typography>
          {msg}
        </Typography>
        <Button
          color='error'
          onClick={() => { close(false) }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  )
}

export default ResponseModal

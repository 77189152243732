import * as React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { getUsername } from 'Helpers/Auth'

const columns = [
  { id: 'side', label: 'Side', minWidth: 70 },
  { id: 'price', label: 'Price', minWidth: 70 },
  { id: 'volume', label: 'Volume', minWidth: 70 },
  { id: 'value', label: 'Value', minWidth: 70 }
]

export const TradeHistory = ({ trades }) => {
  return (
    <Paper sx={{ width: '100%' }} className='paper-col-flex'>
      <Typography variant='h4' component='h2' className='table-title'>
        Trade History
      </Typography>
      <TradeHistoryTable trades={trades} />
    </Paper>
  )
}

const TradeHistoryTable = ({ trades }) => {
  const tradesCopy = trades.map(i => i)
  tradesCopy.reverse()
  return (
    <TableContainer className='full-height scroll'>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TradeHistoryColumn column={column} key={column.id} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tradesCopy.map((row, ii) => (
            <TradeHistoryRow key={row.nonce} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TradeHistoryColumn = ({ column }) => {
  return (
    <TableCell
      key={column.id}
      align='center'
      style={{ minWidth: column.minWidth }}
    >
      {column.label}
    </TableCell>
  )
}

export const TradeHistoryRow = ({ row, username = getUsername() }) => {
  return (
    <TableRow key={row.id} className='market-ticker-row'>
      <TableCell data-testid='tradeHistorySide' align='center'>{username === row.buyerId ? 'Buy' : 'Sell'}</TableCell>
      <TableCell data-testid='tradeHistoryPrice' align='center'>{row.price}</TableCell>
      <TableCell data-testid='tradeHistoryVolume' align='center'>{row.quantity}</TableCell>
      <TableCell data-testid='tradeHistoryValue' align='center'>{row.price * row.quantity}</TableCell>
    </TableRow>
  )
}

import * as React from 'react'
import {
  FormGroup,
  Typography,
  Tabs,
  Tab
} from '@mui/material'

export const RoundManagement = ({ socket }) => {
  const [roundIndex, setRoundIndex] = React.useState(0)
  const [numRounds, setNumRounds] = React.useState(1)

  React.useEffect(() => {
    // subscribe to future updates
    // TODO query current state on start
    socket.on('roundIndexUpdated', setRoundIndex)
    socket.on('numRoundsUpdated', setNumRounds)
    return () => {
      socket.off('roundIndexUpdated', setRoundIndex)
      socket.off('numRoundsUpdated', setNumRounds)
    }
  }, [])
  const tabs = []
  for (let i = 0; i < numRounds; i++) {
    tabs.push(<Tab label={`Round ${i + 1}`} />)
  }
  tabs.push(<Tab label='Make new round' />)
  const handleChange = (event, newValue) => {
    if (newValue > numRounds - 1) {
      socket.emit('makeNewRound')
    }
    socket.emit('setRoundIndex', newValue)
  }

  return (
    <FormGroup>
      <Typography variant='h7' component='h3'>
        Rounds:
      </Typography>
      <Tabs
        value={roundIndex}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable auto tabs'
      >
        {
                    tabs
                }
      </Tabs>
    </FormGroup>
  )
}

import { getUsername } from './Auth'
import { readCookie, updateCookie } from './CookieHelper'
import { apiURL } from './UrlHelper'

export const orderBookSubscribe = (socket, setOrderBook, setBestQuotes) => {
  const createData = (bid, price, ask) => {
    return { bid, price, ask }
  }

  const updateOrderBook = (recv) => {
    const book = []

    recv.Buy.forEach((row) => (
      book.push(createData(row.volume, row.price, ''))
    ))

    recv.Sell.forEach((row) => (
      book.push(createData('', row.price, row.volume))
    ))

    book.sort((x, y) => { return x.price === y.price ? 0 : (x.price > y.price ? -1 : 1) })

    setOrderBook(book)

    const bestQuotes = {
      bid: 0,
      ask: 0
    }

    if (recv.Buy[0] !== undefined) {
      bestQuotes.bid = recv.Buy[recv.Buy.length - 1].price
    }

    if (recv.Sell[0] !== undefined) {
      bestQuotes.ask = recv.Sell[0].price
    }
  }

  // Get the orderbook once
  socket.emit('fetchOrderBook', undefined, updateOrderBook)
  // subscribe to future updates
  socket.on('orderBook', updateOrderBook)
  return () => { socket.off('orderBook', updateOrderBook) }
}

export const tradesSubscribe = (socket, allTrades, setAllTrades, myTrades, setMyTrades) => {
  // subscribe to trade broadcasts
  const updateTrades = (trade) => {
    setAllTrades(allTrades => ([...allTrades, trade]))

    if (trade.buyerId === getUsername() ||
    trade.sellerId === getUsername()) {
      setMyTrades(myTrades => ([...myTrades, trade]))
    }
  }

  socket.emit('fetchTradeHistory', undefined, (trades) => {
    // We can't pipe these through updateTrades because of side effects; we have to use a setter
    // Side effects means it's sometimes possible for a component to render twice, then our socket fetcher
    // fires twice, and we get duplicate trade entries.
    // React.strictMode called me out on this already.

    // Check if trades is nonexistent, for backwards compatibility
    if (trades !== undefined) {
      setAllTrades(trades)
      setMyTrades(trades.filter(trade => (
        trade.buyerId === readCookie('username') || trade.sellerId === readCookie('username')
      )))
    }
  })

  socket.on('tradeOccurred', updateTrades)

  // Reset MTT when game resets
  const clearTradesOnReset = (recv) => {
    if (recv === 'RESET') {
      setAllTrades([])
      setMyTrades([])
    }
  }

  const clearTrades = (data) => {
    setAllTrades([])
    setMyTrades([])
  }

  socket.on('roundChanged', clearTrades)

  // socket.on("competitionStateUpdated", clearTradesOnReset);

  return () => {
    socket.off('tradeOccurred', updateTrades)
    socket.off('competitionStateUpdated', clearTradesOnReset)
    socket.off('roundChanged', clearTrades)
  }
}

export const httpRequest = (msg, route, method, auth, response) => {
  const options = {
    method,
    headers: {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + (auth === true ? readCookie('accessToken') : '')
    },
    body: JSON.stringify(msg)
  }

  fetch(apiURL() + route, options)
    .then((resp) => resp.json())
    .then((data) => response(data))
}

export const verifyRequest = (route) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  }

  return fetch(apiURL() + route, options)
    .then(
      // TODO fix this code block
      (r) => {
        if (r.status !== 200) {
          throw (r)
        }
        return r.json()
      })
}

export const leaderboardSubscribe = (socket, setLeaderboard) => {
  // subscribe to trade broadcasts
  socket.emit('fetchLeaderboard', undefined, setLeaderboard)
  socket.on('leaderboardUpdated', setLeaderboard)

  return () => { socket.off('leaderboardUpdated', setLeaderboard) }
}

export const submitBuySellOrder = (order, callback) => {
  httpRequest(order, '/placeOrder', 'PUT', true, (data) => callback(data.msg))
}

export const submitCancelOrder = (order, callback) => {
  httpRequest(order, '/cancelOrder', 'DELETE', true, (data) => callback(data.msg))
}

export const submitCompetitionState = (state, callback) => {
  httpRequest(state, '/competitionState', 'PUT', true, (data) => console.log(data.msg))
}

export const cancelAllOrders = (callback) => {
  httpRequest('', '/orders', 'DELETE', true, (data) => console.log(data.msg))
}

export const doAuth = (path, userName, password, name) => {
  const loginInfo = { userName, password, name }
  const options = getOptions('POST', loginInfo)

  return fetch(apiURL() + path, options)
    .then(
      // TODO fix this code block
      (r) => {
        if (r.status !== 200) {
          throw (r)
        }
        return r.json()
      })
    .then((data) => {
      if (path === '/login') {
        updateCookie('isAdmin', data.isAdmin)
        updateCookie('accessToken', data.accessToken)
        updateCookie('username', userName)
      }
    })
}

const getOptions = (method, body, token) => {
  return {
    method,
    headers: {
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
}

import { submitBuySellOrder, submitCancelOrder } from 'Helpers/Network'

/**
 * Parses, validates and actions an order. Processors will throw plaintext
 * errors on failure, which must be caught by caller.
 * @param {string} cmd: A string command.
 * @param {string, optional} userName: a string username. If no username
 *      provided, the command parser will check for a username in the command.
 * @param {function} serverResponseCallback: A function that is called by the server
 *      when the order is validated in the backend.
 */
export const processCommand = (cmd, serverResponseCallback, userName) => {
  const hasUserName = userName !== undefined

  let splitCommand = cmd.split(' ')
  // Trim leading spaces, trailing spaces, double spaces
  splitCommand = splitCommand.filter(i => i.length > 0)
  if (!hasUserName) {
    if (splitCommand.length < 2) {
      throw Error('Please enter a valid username and command.')
    } else {
      // take the first element and remove it from the array
      // using Array.shift()
      userName = splitCommand.shift()
    }
  } else {
    if (splitCommand.length < 1) {
      throw Error('Please enter a valid command.')
    }
  }

  const commandType = getCommandType(splitCommand)
  const order = commandProcessors[commandType].parse(splitCommand)
  order.userName = userName
  commandProcessors[commandType].submit(order, serverResponseCallback)
}

// Determine the command type. Add new command keywords here.
const getCommandType = (cmd) => {
  if (cmd.length < 1) {
    throw Error('Please enter a valid command.')
  }

  const commandType = cmd[0].toLocaleLowerCase()

  if (commandType === 'b' || commandType === 'buy' ||
        commandType === 's' || commandType === 'sell'
  ) {
    return 'cBuySell'
  } else if (commandType === 'c' || commandType === 'cancel') {
    return 'cCancel'
  } else if (commandType === 'c!' || commandType === 'cancel-all') {
    return 'cCancelAll'
  } else {
    throw Error('Invalid command type')
  }
}

// Process commands. Each processor should have a parse, validate, and submit step.
const commandProcessors = {
  cBuySell: {
    parse: (cmd) => {
      if (cmd.length !== 3) throw Error('Command has an invalid number of arguments')

      let side = cmd[0]
      if (side === 'b' || side.toLocaleLowerCase() === 'buy') side = 'Buy'
      else if (side === 's' || side.toLocaleLowerCase() === 'sell') side = 'Sell'

      const volumeText = cmd[1]
      const priceText = cmd[2]
      if (String(Math.floor(Number(volumeText))) !== volumeText) {
        throw Error('ERROR: Invalid quantity (no decimals allowed!): ' + volumeText)
      }

      if (Number(volumeText) <= 0) {
        throw Error('ERROR: Invalid quantity (must be positive!): ' + volumeText)
      }

      if (String(Number(priceText)) !== priceText) {
        throw Error('ERROR: Invalid price (must be numeric): ' + priceText)
      }

      // Return an order object
      return {
        side,
        volume: Number(volumeText),
        price: Number(priceText)
      }
    },
    submit: submitBuySellOrder
  },
  cCancel: {
    parse: (cmd) => {
      const priceAttempt = Number(cmd[1])
      if (isNaN(priceAttempt)) {
        throw Error('ERROR: Invalid price (must be numeric): ' + cmd[1])
      }
      if (cmd.length > 2) {
        throw Error('ERROR: Too many arguments for cancel.')
      }
      return {
        // Cancel order only needs one argument
        price: priceAttempt
      }
    },
    submit: submitCancelOrder
  },
  cCancelAll: {
    parse: (cmd) => {
      // Return a cancel all command
      if (cmd.length > 1) {
        throw Error('ERROR: Too many arguments for cancel all.')
      }
      return {
        // cancelAll is flag to backend
        cancelAll: true
      }
    },
    submit: submitCancelOrder
  }
}

import * as React from 'react'
import { Button } from '@mui/material'
import { submitBuySellOrder } from 'Helpers/Network'
import { getUsername } from 'Helpers/Auth'

const iocVolume = 5

export const IOCButtons = ({ bestBid, bestAsk }) => {
  return (
    <div>
      <Button
        color='success' variant='outlined' onClick={() => submitBuySellOrder({
          userName: getUsername(),
          side: 'Buy',
          volume: iocVolume,
          price: bestBid
        })}
      >
        Best Bid
      </Button>
      <Button
        color='error' variant='outlined' onClick={() => submitBuySellOrder({
          userName: getUsername(),
          side: 'Sell',
          volume: iocVolume,
          price: bestAsk
        })}
      >
        Best ask
      </Button>
    </div>
  )
}

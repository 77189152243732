import { readCookie, updateCookie } from './CookieHelper'

/**
 * This function returns a route to direct to which requires authorization or the
 * login route if the user is not currently authorized
 *
 * @param {string} path The page to redirect to if a user is not logged in
 *
 * @returns Route to direct to. If not logged in
 */
export const authRedirect = (path) => {
  if (isLoggedIn()) {
    return path
  } else {
    return '/login'
  }
}

// Convenience function so we don't accidentally readCookie("userName"),
// or at least we are warned about it at compile time
export const getUsername = () => {
  return readCookie('username')
}

export const isLoggedIn = () => {
  return readCookie('accessToken') !== ''
}

export const doLogout = () => {
  updateCookie('accessToken', '')
}

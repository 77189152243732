import * as React from 'react'
import { Leaderboard } from 'Components/Leaderboard'
import { leaderboardSubscribe } from 'Helpers/Network'

export default ({ socket }) => {
  const [leaderboard, setLeaderboard] = React.useState({})

  React.useEffect(() => {
    return leaderboardSubscribe(socket, setLeaderboard)
  }, [])

  return (
    <div className='full-height scroll projector-scale'>
      <Leaderboard leaderboard={leaderboard} />
    </div>
  )
}

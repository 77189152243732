import React from 'react'
import './index.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import AdminPanel from 'Routes/AdminPanel'
import { io } from 'socket.io-client'
import { wsURL } from 'Helpers/UrlHelper'
import TradingInterface from 'Routes/TradingInterface'
import Projector from 'Routes/Projector'
import { DisconnectedModal } from 'Components/DisconnectedModal'
import LeaderboardPanel from 'Routes/LeaderboardPanel'
import { Header } from 'Components/Header'
import { Box } from '@mui/system'
import { LandingPage } from 'Routes/LandingPage'
import { isLoggedIn } from 'Helpers/Auth'
import { AuthScreen } from 'Routes/AuthScreen'
import { VerifyScren } from 'Routes/Verify'

export const App = () => {
  const [auth, setAuth] = React.useState('auth')
  const socket = io(wsURL())

  React.useState(() => {
    setAuth(isLoggedIn())
  }, [])

  return (
    <>
      <DisconnectedModal socket={socket} />
      <BrowserRouter>
        <Box id='main-outer-container'>
          <Header auth={auth} />
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='admin' element={<AdminPanel socket={socket} />} />
            <Route path='compete' element={<TradingInterface socket={socket} />} />
            <Route path='projector' element={<Projector socket={socket} />} />
            <Route path='leaderboard' element={<LeaderboardPanel socket={socket} />} />
            <Route path='login' element={<AuthScreen setAuth={setAuth} />} />
            <Route path='verify' element={<VerifyScren setAuth={setAuth} />} />
            <Route path='register' element={<AuthScreen setAuth={setAuth} register />} />
            <Route path='logout' element={<LandingPage logout setAuth={setAuth} />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </>
  )
}

export function apiURL () {
  if (window.location.href.includes('localhost')) {
    // We are testing
    return 'http://localhost:9327'
  } else {
    // In prod
    return 'https://raven.quanttraders.club'
  }
}

export function wsURL () {
  if (window.location.href.includes('localhost')) {
    // We are testing
    return 'http://localhost:9327'
  } else {
    // In prod
    return 'https://raven.quanttraders.club'
  }
}

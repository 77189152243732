/*
  Update a prop value in the cookie. Cookie values are stored in key-data pairs
  like the following:

  key1=value1;key2=value2;

  NOTE this replaces the entire cookie with the key-value pair given to it.
  Would need a more complex system if storing more than just the AccessToken,
  but I cannot think of a case where we would need to store any more info.
*/
export const updateCookie = (key, value) => {
  document.cookie = `${key}=${value}`
}

/*
  Retrieve the value associated with a cookie key. Cookie is split by delineator
  ";" and the value stored is returned.
*/
export const readCookie = (cookieKey) => {
  cookieKey = cookieKey + '='
  const cookie = decodeURIComponent(document.cookie).split('; ')

  const cookieData = cookie.find((entry) =>
    entry.startsWith(cookieKey))

  return cookieData
    ? cookieData.substring(cookieKey.length, cookieData.length)
    : ''
}

import React from 'react'
import {
  TextField,
  Grid,
  Button
} from '@mui/material'
import { updateCookie } from '../Helpers/CookieHelper'
import { useNavigate } from 'react-router-dom'
import { verifyRequest } from 'Helpers/Network'

import ResponseModal from 'Components/ResponseModal'

export const VerifyScren = ({ setAuth }) => {
  const [name, setName] = React.useState('')
  const [inviteCode, setInviteCode] = React.useState('')
  const [error, setError] = React.useState('')

  const navigate = useNavigate()

  const doVerify = () => {
    verifyRequest(`/verify?inviteCode=${inviteCode}&username=${name}`).then((data) => {
      console.log(data.accessToken)
      updateCookie('accessToken', data.accessToken)
      updateCookie('username', name)
      setAuth(true)
      navigate('/compete')
    }).catch(async (msg) => {
      setError((await msg.json()).msg)
    })
  }

  return (
    <>
      <form onSubmit={doVerify}>
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <TextField
            variant='standard'
            id='name-input'
            label='Username'
            margin='normal'
            name='name'
            required
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <TextField
            variant='standard'
            id='invite-code-input'
            label='Invite Code'
            margin='normal'
            name='inviteCode'
            required
            onChange={(e) => setInviteCode(e.target.value)}
            value={inviteCode}
          />
          <Button id='verify' variant='outlined' onClick={() => doVerify()}>Verify</Button>
        </Grid>
      </form>
      {error !== ''
        ? (
          <ResponseModal setError={setError} msg={error} visible />
          )
        : (<></>)}
    </>
  )
}

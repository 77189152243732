import * as React from 'react'
import 'Styles/App.css'
import 'Styles/Projector.css'
import { MarketTradeTicker } from 'Components/MarketTradeTicker'
import { OrderBook } from 'Components/OrderBook'
import { Grid } from '@mui/material'
import { orderBookSubscribe, tradesSubscribe } from 'Helpers/Network'

export default function Projector ({ socket }) {
  const [orderBook, setOrderBook] = React.useState([])
  const [bestQuotes, setBestQuotes] = React.useState({
    bid: 0,
    ask: 0
  })
  const [allTrades, setAllTrades] = React.useState([])
  const [myTrades, setMyTrades] = React.useState([])

  React.useEffect(() => {
    return orderBookSubscribe(socket, setOrderBook, setBestQuotes)
  }, [])

  React.useEffect(() => {
    return tradesSubscribe(socket, allTrades, setAllTrades, myTrades, setMyTrades)
  }, [])

  return (
    <Grid className='browser-height projector-scale' container spacing={2} direction='row' padding={2}>
      <Grid item xs={12} sm={4} className='trading-interface-desktop full-height'>
        <OrderBook orderBook={orderBook} bestBid={bestQuotes.bid} bestAsk={bestQuotes.ask} />
      </Grid>

      <Grid item sm={8} className='full-height' paddingTop={2}>
        <MarketTradeTicker trades={allTrades} />
      </Grid>
    </Grid>
  )
}

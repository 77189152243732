import * as React from 'react'
import { Grid, Typography } from '@mui/material'
import 'Styles/PositionMonitor.css'
import { getUsername } from 'Helpers/Auth'

export const PositionMonitor = ({
  myTrades,
  username = getUsername()
}) => {
  const getNetPosition = (total, trade) => {
    if (trade.buyerId === username) {
      total = total + trade.quantity
    }
    // No elseif here while we still have wash trades
    if (trade.sellerId === username) {
      total = total - trade.quantity
    }
    return total
  }

  const getNetProfit = (total, trade) => {
    if (trade.buyerId === username) {
      total = total - trade.quantity * trade.price
    }
    // No elseif here while we still have wash trades
    if (trade.sellerId === username) {
      total = total + trade.quantity * trade.price
    }
    return total
  }

  return (
    <Grid container fullWidth className='position-monitor'>
      <Grid item xs={12}>
        <Typography>
          Position Monitor
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography data-testid='netPosition'>
          Net Position: {myTrades.reduce(getNetPosition, 0)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography data-testid='netCash'>
          Cash Pre-Settle: {myTrades.reduce(getNetProfit, 0)}
        </Typography>
      </Grid>
    </Grid>
  )
}
